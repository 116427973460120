import React from 'react'

import { MailIcon, PhoneIcon } from '@heroicons/react/outline'

import { CheckCircleIcon, XIcon } from '@heroicons/react/solid'


import ContactForm from './ContactForm'

import Mail from "@material-ui/icons/Mail";
import PhoneEnabled from "@material-ui/icons/Phone";

import BackgroundImage from 'gatsby-background-image'

import {useForm} from '@formspree/react'

const Contact = ({background}) => {

    const [state, handleSubmit] = useForm("xrgrvdyl")

    if (state.succeeded){
        return (
            <div id = "contact" className="bg-gray-50">
<div className="max-w-[90rem] mx-auto py-16 px-4 sm:py-24 sm:px-6 lg:px-8">
  <div className="relative bg-white rounded shadow-xl">
    <h2 className="sr-only">Contact us</h2>

    <div className="grid grid-cols-1 lg:grid-cols-3">
      {/* Contact information */}
      <div className="relative rounded sm:rounded-bl-none sm:rounded-br-none lg:rounded-tr-none lg:rounded-br-none overflow-hidden py-10 px-6 bg-ag-dark-green sm:px-10 xl:p-12">
        <div className="absolute inset-0 pointer-events-none sm:hidden" aria-hidden="true">
          <svg
            className="absolute inset-0 w-full h-full"
            width={343}
            height={388}
            viewBox="0 0 343 388"
            fill="none"
            preserveAspectRatio="xMidYMid slice"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M-99 461.107L608.107-246l707.103 707.107-707.103 707.103L-99 461.107z"
              fill="url(#linear1)"
              fillOpacity=".1"
            />
            <defs>
              <linearGradient
                id="linear1"
                x1="254.553"
                y1="107.554"
                x2="961.66"
                y2="814.66"
                gradientUnits="userSpaceOnUse"
              >
                <stop stopColor="#fff" />
                <stop offset={1} stopColor="#fff" stopOpacity={0} />
              </linearGradient>
            </defs>
          </svg>
        </div>
        <div
          className="hidden absolute top-0 right-0 bottom-0 w-1/2 pointer-events-none sm:block lg:hidden"
          aria-hidden="true"
        >
          <svg
            className="absolute inset-0 w-full h-full"
            width={359}
            height={339}
            viewBox="0 0 359 339"
            fill="none"
            preserveAspectRatio="xMidYMid slice"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M-161 382.107L546.107-325l707.103 707.107-707.103 707.103L-161 382.107z"
              fill="url(#linear2)"
              fillOpacity=".1"
            />
            <defs>
              <linearGradient
                id="linear2"
                x1="192.553"
                y1="28.553"
                x2="899.66"
                y2="735.66"
                gradientUnits="userSpaceOnUse"
              >
                <stop stopColor="#fff" />
                <stop offset={1} stopColor="#fff" stopOpacity={0} />
              </linearGradient>
            </defs>
          </svg>
        </div>
        <div
          className="hidden absolute top-0 right-0 bottom-0 w-1/2 pointer-events-none lg:block"
          aria-hidden="true"
        >
          <svg
            className="absolute inset-0 w-full h-full"
            width={160}
            height={678}
            viewBox="0 0 160 678"
            fill="none"
            preserveAspectRatio="xMidYMid slice"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M-161 679.107L546.107-28l707.103 707.107-707.103 707.103L-161 679.107z"
              fill="url(#linear3)"
              fillOpacity=".1"
            />
            <defs>
              <linearGradient
                id="linear3"
                x1="192.553"
                y1="325.553"
                x2="899.66"
                y2="1032.66"
                gradientUnits="userSpaceOnUse"
              >
                <stop stopColor="#fff" />
                <stop offset={1} stopColor="#fff" stopOpacity={0} />
              </linearGradient>
            </defs>
          </svg>
        </div>
        <h3 className="text-lg font-medium text-white">Contact information</h3>
        <p className="mt-6 text-base text-white max-w-3xl">
          Want to have a chat about any of our services or discuss your next project? Send us a message and we will get back to you as soon as we can. 
        </p>
        <dl className="mt-8 space-y-6">
          <dt>
            <span className="sr-only">Phone number</span>
          </dt>
          <dd className="flex text-base text-white">
            <PhoneIcon className="flex-shrink-0 w-6 h-6 text-white" aria-hidden="true" />
            <span className="ml-3">0457 520 201</span>
          </dd>
          <dt>
            <span className="sr-only">Email</span>
          </dt>
          <dd className="flex text-base text-white">
            <MailIcon className="flex-shrink-0 w-6 h-6 text-white" aria-hidden="true" />
            <span className="ml-3">neville@afterglowservices.com.au</span>
          </dd>
        </dl>
        <ul role="list" className="mt-8 flex space-x-12">
          <li>
            <a className="text-white hover:text-white" href="#">
              <span className="sr-only">Facebook</span>
              <svg
                width={24}
                height={24}
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className="w-6 h-6"
                aria-hidden="true"
              >
                <path
                  d="M22.258 1H2.242C1.556 1 1 1.556 1 2.242v20.016c0 .686.556 1.242 1.242 1.242h10.776v-8.713h-2.932V11.39h2.932V8.887c0-2.906 1.775-4.489 4.367-4.489 1.242 0 2.31.093 2.62.134v3.037l-1.797.001c-1.41 0-1.683.67-1.683 1.653v2.168h3.362l-.438 3.396h-2.924V23.5h5.733c.686 0 1.242-.556 1.242-1.242V2.242C23.5 1.556 22.944 1 22.258 1"
                  fill="currentColor"
                />
              </svg>
            </a>
          </li>
          <li>
            <a className="text-white hover:text-white" href="tel:0457520201">
              <PhoneEnabled/>
            </a>
          </li>
          <li>
            <a className="text-white hover:text-white" href="mailto:neville@afterglowservices.com.au">
              <Mail/>
            </a>
          </li>
        </ul>
      </div>

      {/* Contact form */}
      <div className="py-10 px-6 sm:px-10 lg:col-span-2 xl:p-12">
        <h3 className="text-lg font-medium text-gray-900">Send us a message</h3>
        <form action="#" method="POST" className="mt-6 grid grid-cols-1 gap-y-6 sm:grid-cols-2 sm:gap-x-8" onSubmit= {handleSubmit}>
          <div>
            <label htmlFor="first-name" className="block text-sm font-medium text-gray-900">
              First name
            </label>
            <div className="mt-1">
              <input
                type="text"
                name="first-name"
                id="first-name"
                autoComplete="given-name"
                className="py-3 px-4 block w-full shadow-sm text-gray-900 focus:ring-ag-dark-green focus:border-ag-dark-green border border-gray-300 rounded-md"
              />
            </div>
          </div>
          <div>
            <label htmlFor="last-name" className="block text-sm font-medium text-gray-900">
              Last name
            </label>
            <div className="mt-1">
              <input
                type="text"
                name="last-name"
                id="last-name"
                autoComplete="family-name"
                className="py-3 px-4 block w-full shadow-sm text-gray-900 focus:ring-ag-dark-green focus:border-ag-dark-green border border-gray-300 rounded-md"
              />
            </div>
          </div>
          <div>
            <label htmlFor="email" className="block text-sm font-medium text-gray-900">
              Email
            </label>
            <div className="mt-1">
              <input
                id="email"
                name="email"
                type="email"
                autoComplete="email"
                className="py-3 px-4 block w-full shadow-sm text-gray-900 focus:ring-ag-dark-green focus:border-ag-dark-green border border-gray-300 rounded-md"
              />
            </div>
          </div>
          <div>
            <div className="flex justify-between">
              <label htmlFor="phone" className="block text-sm font-medium text-gray-900">
                Phone
              </label>
              <span id="phone-optional" className="text-sm text-gray-500">
                Optional
              </span>
            </div>
            <div className="mt-1">
              <input
                type="text"
                name="phone"
                id="phone"
                autoComplete="tel"
                className="py-3 px-4 block w-full shadow-sm text-gray-900 focus:ring-ag-dark-green focus:border-ag-dark-green border border-gray-300 rounded-md"
                aria-describedby="phone-optional"
              />
            </div>
          </div>

          <div className="sm:col-span-2">
            <div className="flex justify-between">
              <label htmlFor="message" className="block text-sm font-medium text-gray-900">
                Brief description of project
              </label>
              <span id="message-max" className="text-sm text-gray-500">
                Max. 500 characters
              </span>
            </div>
            <div className="mt-1">
              <textarea
                id="message"
                name="message"
                rows={4}
                className="py-3 px-4 block w-full shadow-sm text-gray-900 focus:ring-ag-dark-green focus:border-ag-dark-green border border-gray-300 rounded-md"
                aria-describedby="message-max"
                defaultValue={''}
              />
            </div>
          </div>
          <div className="sm:col-span-2 sm:flex sm:justify-end">
            <button
              type="submit"
              disabled = {state.submitting}
              className="mt-2 w-full inline-flex items-center justify-center px-6 py-3 border border-transparent rounded-md shadow-sm text-base font-medium text-white bg-ag-dark-green hover:bg-ag-light-green focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-ag-dark-green sm:w-auto transition"
            >
              Submit
            </button>
            <div className="rounded-md bg-green-50 p-4">
      <div className="flex">
        <div className="flex-shrink-0">
          <CheckCircleIcon className="h-5 w-5 text-green-400" aria-hidden="true" />
        </div>
        <div className="ml-3">
          <p className="text-sm font-medium text-green-800">Thanks! Your submission has been received</p>
        </div>
        <div className="ml-auto pl-3">
          <div className="-mx-1.5 -my-1.5">
            <button
              type="button"
              onClick = {window.location.reload(false)}
              className="inline-flex bg-green-50 rounded-md p-1.5 text-green-500 hover:bg-green-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-green-50 focus:ring-green-600"
            >
              <span className="sr-only">Dismiss</span>
              <XIcon className="h-5 w-5" aria-hidden="true" />
            </button>
          </div>
        </div>
      </div>
    </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
</div>
        )
    }
    return (
        // <BackgroundImage {...background} preserveStackingContext>
        //     <div id= "contact" className="container">
        //         <div className= "contact-wrapper">
        //             <div className="contact-info">
        //                 <div>
        //                 <h2>Get in Touch</h2>
        //                 <p>Need more information about any of our services? Send us a message or give us a call and we will get back to you as soon as we can. </p>
        //                 </div>
        //                 <div className= "address-block">
        //                     <div className="contact-block">
        //                         <div className="address-wrapper">
        //                             <PinDropIcon/>
        //                             <h3>Find us in the office</h3>
        //                         </div>
        //                         <p>Arncliffe Road</p>
        //                         <p>Austins Ferry, TAS</p>
        //                         <p>Mon - Fri, 8:00 - 17: 00</p>
        //                     </div>
                            
        //                     <div className="contact-block">
        //                         <div className="address-wrapper">
        //                             <PhoneEnabled/>
        //                             <h3>Give us a ring</h3>
        //                         </div>
        //                         <p>Neville Lewis</p>
        //                         <p>0457 520 201</p>
        //                         <p>Mon - Fri, 8:00 - 17: 00</p>
        //                     </div>
        //                 </div>
        //             </div>
        //             <div className="form-wrapper">
        //                 <ContactForm/>
        //             </div>
        //         </div>
        //     </div>
        // </BackgroundImage>

<div id = "contact" className="bg-gray-50">
<div className="max-w-[90rem] mx-auto py-16 px-4 sm:py-24 sm:px-6 lg:px-8">
  <div className="relative bg-white rounded shadow-xl">
    <h2 className="sr-only">Contact us</h2>

    <div className="grid grid-cols-1 lg:grid-cols-3">
      {/* Contact information */}
      <div className="relative rounded-tr rounded-tl lg:rounded-tr-none lg:rounded-bl overflow-hidden py-10 px-6 bg-ag-dark-green sm:px-10 xl:p-12">
        <div className="absolute inset-0 pointer-events-none sm:hidden" aria-hidden="true">
          <svg
            className="absolute inset-0 w-full h-full"
            width={343}
            height={388}
            viewBox="0 0 343 388"
            fill="none"
            preserveAspectRatio="xMidYMid slice"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M-99 461.107L608.107-246l707.103 707.107-707.103 707.103L-99 461.107z"
              fill="url(#linear1)"
              fillOpacity=".1"
            />
            <defs>
              <linearGradient
                id="linear1"
                x1="254.553"
                y1="107.554"
                x2="961.66"
                y2="814.66"
                gradientUnits="userSpaceOnUse"
              >
                <stop stopColor="#fff" />
                <stop offset={1} stopColor="#fff" stopOpacity={0} />
              </linearGradient>
            </defs>
          </svg>
        </div>
        <div
          className="hidden absolute top-0 right-0 bottom-0 w-1/2 pointer-events-none sm:block lg:hidden"
          aria-hidden="true"
        >
          <svg
            className="absolute inset-0 w-full h-full"
            width={359}
            height={339}
            viewBox="0 0 359 339"
            fill="none"
            preserveAspectRatio="xMidYMid slice"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M-161 382.107L546.107-325l707.103 707.107-707.103 707.103L-161 382.107z"
              fill="url(#linear2)"
              fillOpacity=".1"
            />
            <defs>
              <linearGradient
                id="linear2"
                x1="192.553"
                y1="28.553"
                x2="899.66"
                y2="735.66"
                gradientUnits="userSpaceOnUse"
              >
                <stop stopColor="#fff" />
                <stop offset={1} stopColor="#fff" stopOpacity={0} />
              </linearGradient>
            </defs>
          </svg>
        </div>
        <div
          className="hidden absolute top-0 right-0 bottom-0 w-1/2 pointer-events-none lg:block"
          aria-hidden="true"
        >
          <svg
            className="absolute inset-0 w-full h-full"
            width={160}
            height={678}
            viewBox="0 0 160 678"
            fill="none"
            preserveAspectRatio="xMidYMid slice"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M-161 679.107L546.107-28l707.103 707.107-707.103 707.103L-161 679.107z"
              fill="url(#linear3)"
              fillOpacity=".1"
            />
            <defs>
              <linearGradient
                id="linear3"
                x1="192.553"
                y1="325.553"
                x2="899.66"
                y2="1032.66"
                gradientUnits="userSpaceOnUse"
              >
                <stop stopColor="#fff" />
                <stop offset={1} stopColor="#fff" stopOpacity={0} />
              </linearGradient>
            </defs>
          </svg>
        </div>
        <h3 className="text-lg font-medium text-white">Contact information</h3>
        <p className="mt-6 text-base text-white max-w-3xl">
          Want to have a chat about any of our services or discuss your next project? Send us a message and we will get back to you as soon as we can. 
        </p>
        <dl className="mt-8 space-y-6">
          <dt>
            <span className="sr-only">Phone number</span>
          </dt>
          <dd className="flex text-base text-white">
            <PhoneIcon className="flex-shrink-0 w-6 h-6 text-white" aria-hidden="true" />
            <span className="ml-3">0457 520 201</span>
          </dd>
          <dt>
            <span className="sr-only">Email</span>
          </dt>
          <dd className="flex text-base text-white">
            <MailIcon className="flex-shrink-0 w-6 h-6 text-white" aria-hidden="true" />
            <span className="ml-3">neville@afterglowservices.com.au</span>
          </dd>
        </dl>
        <ul role="list" className="mt-8 flex space-x-12">
          <li>
            <a className="text-white hover:text-white" href="#">
              <span className="sr-only">Facebook</span>
              <svg
                width={24}
                height={24}
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className="w-6 h-6"
                aria-hidden="true"
              >
                <path
                  d="M22.258 1H2.242C1.556 1 1 1.556 1 2.242v20.016c0 .686.556 1.242 1.242 1.242h10.776v-8.713h-2.932V11.39h2.932V8.887c0-2.906 1.775-4.489 4.367-4.489 1.242 0 2.31.093 2.62.134v3.037l-1.797.001c-1.41 0-1.683.67-1.683 1.653v2.168h3.362l-.438 3.396h-2.924V23.5h5.733c.686 0 1.242-.556 1.242-1.242V2.242C23.5 1.556 22.944 1 22.258 1"
                  fill="currentColor"
                />
              </svg>
            </a>
          </li>
          <li>
            <a className="text-white hover:text-white" href="tel:0457520201">
              <PhoneEnabled/>
            </a>
          </li>
          <li>
            <a className="text-white hover:text-white" href="mailto:neville@afterglowservices.com.au">
              <Mail/>
            </a>
          </li>
        </ul>
      </div>

      {/* Contact form */}
      <div className="py-10 px-6 sm:px-10 lg:col-span-2 xl:p-12">
        <h3 className="text-lg font-medium text-gray-900">Send us a message</h3>
        <form action="#" method="POST" className="mt-6 grid grid-cols-1 gap-y-6 sm:grid-cols-2 sm:gap-x-8" onSubmit= {handleSubmit}>
          <div>
            <label htmlFor="first-name" className="block text-sm font-medium text-gray-900">
              First name
            </label>
            <div className="mt-1">
              <input
                type="text"
                name="first-name"
                id="first-name"
                autoComplete="given-name"
                className="py-3 px-4 block w-full shadow-sm text-gray-900 focus:ring-ag-dark-green focus:border-ag-dark-green border border-gray-300 rounded-md"
              />
            </div>
          </div>
          <div>
            <label htmlFor="last-name" className="block text-sm font-medium text-gray-900">
              Last name
            </label>
            <div className="mt-1">
              <input
                type="text"
                name="last-name"
                id="last-name"
                autoComplete="family-name"
                className="py-3 px-4 block w-full shadow-sm text-gray-900 focus:ring-ag-dark-green focus:border-ag-dark-green border border-gray-300 rounded-md"
              />
            </div>
          </div>
          <div>
            <label htmlFor="email" className="block text-sm font-medium text-gray-900">
              Email
            </label>
            <div className="mt-1">
              <input
                id="email"
                name="email"
                type="email"
                autoComplete="email"
                className="py-3 px-4 block w-full shadow-sm text-gray-900 focus:ring-ag-dark-green focus:border-ag-dark-green border border-gray-300 rounded-md"
              />
            </div>
          </div>
          <div>
            <div className="flex justify-between">
              <label htmlFor="phone" className="block text-sm font-medium text-gray-900">
                Phone
              </label>
              <span id="phone-optional" className="text-sm text-gray-500">
                Optional
              </span>
            </div>
            <div className="mt-1">
              <input
                type="text"
                name="phone"
                id="phone"
                autoComplete="tel"
                className="py-3 px-4 block w-full shadow-sm text-gray-900 focus:ring-ag-dark-green focus:border-ag-dark-green border border-gray-300 rounded-md"
                aria-describedby="phone-optional"
              />
            </div>
          </div>

          <div className="sm:col-span-2">
            <div className="flex justify-between">
              <label htmlFor="message" className="block text-sm font-medium text-gray-900">
                Brief description of project
              </label>
              <span id="message-max" className="text-sm text-gray-500">
                Max. 500 characters
              </span>
            </div>
            <div className="mt-1">
              <textarea
                id="message"
                name="message"
                rows={4}
                className="py-3 px-4 block w-full shadow-sm text-gray-900 focus:ring-ag-dark-green focus:border-ag-dark-green border border-gray-300 rounded-md"
                aria-describedby="message-max"
                defaultValue={''}
              />
            </div>
          </div>
          <div className="sm:col-span-2 sm:flex sm:justify-end">
            <button
              type="submit"
              disabled = {state.submitting}
              className="mt-2 w-full inline-flex items-center justify-center px-6 py-3 border border-transparent rounded-md shadow-sm text-base font-medium text-white bg-ag-dark-green hover:bg-ag-light-green focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-ag-dark-green sm:w-auto transition"
            >
              Submit
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
</div>
    )
}

export default Contact
