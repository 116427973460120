import React from 'react'

import {Link} from 'gatsby'
import {AnchorLink} from 'gatsby-plugin-anchor-links'

import {GatsbyImage} from 'gatsby-plugin-image'

import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import Button from "components/CustomButtons/Button.jsx";

import {graphql, useStaticQuery} from 'gatsby'

const query = graphql`
    {
        allContentfulServices {
            nodes {
                id
                name
                description
                image {
                    gatsbyImageData(placeholder: BLURRED)
                }
            }
        }
    }

`

const Services = () => {

    const data = useStaticQuery(query)
    const services = data.allContentfulServices.nodes

    return (
        <div id= "services" className= "container">
            <div className="services-wrapper">
                <div className="services-info">
                    <h2>Our Landscaping & Property Services</h2>
                </div>
                {services.map((service, index) => {
                    const image = service.image.gatsbyImageData
                    return (
                        <Card style={{ width: "100%" }} className="service-card">
                            <div className="img-wrapper">
                                <GatsbyImage image = {image} className= "card-img" alt= "services-img"/>
                            </div>
                            <CardBody className= "card-body">
                                <h4 className= "card-title">{service.name}</h4>
                                <p className= "card-content">
                                {service.description}
                                </p>
                                <AnchorLink to= "/#contact"><Button className= "service-btn" simple color="success">Get a Quote Today</Button></AnchorLink>
                            </CardBody>
                        </Card>
                    )
                })}
            </div>
            <div className="btn-wrapper">
            <AnchorLink to="/#contact" className= "anchorlink">      <button
        type="button"
        className="inline-flex items-center my-6 px-6 py-3 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-ag-dark-green hover:bg-ag-light-green focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-ag-dark-green hover:text-white focus:text-white transition"
      >
        Get in Touch
      </button></AnchorLink>
            </div>
            
        </div>
    )
}

export default Services
