import React from "react";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import InputAdornment from "@material-ui/core/InputAdornment";
// @material-ui/icons
import Email from "@material-ui/icons/Email";
import People from "@material-ui/icons/People";
import Phone from '@material-ui/icons/Phone'

// React icons

// core components

import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardFooter from "components/Card/CardFooter.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";

import loginPageStyle from "assets/jss/material-kit-react/views/loginPage.jsx";


class ContactForm extends React.Component {

    constructor(props) {
    super(props);
    // we use this to make the card to appear after the page has been rendered
    this.state = {
        cardAnimaton: "cardHidden"
        };
    }
    componentDidMount() {
    // we add a hidden class to the card and after 700 ms we delete it and the transition appears
    setTimeout(
        function() {
        this.setState({ cardAnimaton: "" });
        }.bind(this),
        700
        );
    }

      render() {
        const { classes, ...rest } = this.props;
        return (
            <Card className={classes[this.state.cardAnimaton]}>
            <form className={classes.form} action= "https://formspree.io/f/xrgrvdyl" method= "POST">
              <CardBody>
                <CustomInput
                  labelText="Full Name..."
                  id="full"
                  formControlProps={{
                    fullWidth: true
                  }}
                  inputProps={{
                    type: "text",
                    name: "name",
                    endAdornment: (
                      <InputAdornment position="end">
                        <People className={classes.inputIconsColor} />
                      </InputAdornment>
                    )
                  }}
                />
                <CustomInput
                  labelText="Email..."
                  id="email"
                  formControlProps={{
                    fullWidth: true
                  }}
                  inputProps={{
                    type: "email",
                    name: "email",
                    endAdornment: (
                      <InputAdornment position="end">
                        <Email className={classes.inputIconsColor} />
                      </InputAdornment>
                    )
                  }}
                />
                <CustomInput
                  labelText="Preferred Contact no..."
                  id="phone"
                  formControlProps={{
                    fullWidth: true
                  }}
                  inputProps={{
                    type: "telephone",
                    name: "phone",
                    endAdornment: (
                      <InputAdornment position="end">
                        <Phone/>
                      </InputAdornment>
                    )
                  }}
                />
                <CustomInput
                  labelText="Brief description"
                  id="expertise"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    multiline: true,
                    name: "brief description of services",
                    className: "form-text",
                  }}
                />
              </CardBody>
              <CardFooter className={classes.cardFooter}>
                <Button  className= "form-btn" color="success" size="lg" type= "submit">
                  Submit Request
                </Button>
              </CardFooter>
            </form>
          </Card>
       );
    }
}   

export default withStyles(loginPageStyle)(ContactForm);