import React from 'react'

import Parallax from "components/Parallax/Parallax.jsx";
import image from '../assets/images/hero2.png'

import Button from "components/CustomButtons/Button.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import InputAdornment from "@material-ui/core/InputAdornment";

import Email from "@material-ui/icons/Email";
import People from "@material-ui/icons/People";
import Phone from '@material-ui/icons/Phone'

import withStyles from "@material-ui/core/styles/withStyles";
import componentsStyle from "assets/jss/material-kit-react/views/components.jsx";



import {graphql, useStaticQuery} from 'gatsby'

import classNames from "classnames";

class Hero extends React.Component {
    render () {
      const { classes, ...rest } = this.props;



      return (
        <>
        <Parallax image= {this.props.image} className= "hero-section">
            <div className={classes.container}>
            <div className= "hero-content">
                <div className="hero-text-wrapper">
                    <h1 className="hero-title">{this.props.heading}</h1>
                    <h3 className= "hero-subtitle">{this.props.subHeading}</h3>
                </div>
            </div>
            </div>
        </Parallax>
        <div className={classes.container}>
            <div className={classNames(classes.main, classes.mainRaised)}>
                <form className= "hero-form" action= "https://formspree.io/f/xrgrvdyl" method= "POST">
                <CustomInput
                  labelText="Full Name..."
                  id="full"
                  formControlProps={{
                    fullWidth: true
                  }}
                  inputProps={{
                    type: "text",
                    name: "name",
                    endAdornment: (
                      <InputAdornment position="end">
                        <People className={classes.inputIconsColor} />
                      </InputAdornment>
                    )
                  }}
                />
                    <CustomInput
                    labelText="Email..."
                    id="email"
                    formControlProps={{
                        fullWidth: true
                    }}
                    inputProps={{
                        type: "email",
                        name: "email",
                        endAdornment: (
                        <InputAdornment position="end">
                            <Email className={classes.inputIconsColor} />
                        </InputAdornment>
                        )
                    }}
                    />
                    <CustomInput
                    labelText="Preferred Contact no..."
                    id="phone"
                    formControlProps={{
                        fullWidth: true
                    }}
                    inputProps={{
                        type: "telephone",
                        name: "phone",
                        endAdornment: (
                        <InputAdornment position="end">
                            <Phone/>
                        </InputAdornment>
                        )
                    }}
                    />
                    <div className="btn-wrapper">
                        <Button  className= "hero-btn" color="success" size="lg" type= "submit">
                        Hear from our Team
                        </Button>
                    </div>
                </form>
            </div>
        </div>
        </>

      )
    }
    
  }
  
  export default withStyles(componentsStyle)(Hero);