import React from 'react'

import Layout from '../components/Layout'
import SEO from '../components/SEO'

import Hero from '../components/Hero'
import Services from '../components/Services'
import Contact from '../components/Contact'
import Gallery from '../components/Gallery'

import "assets/scss/material-kit-react.scss?v=1.4.0";
import 'typeface-roboto';
import 'typeface-roboto-slab';

import { convertToBgImage } from "gbimage-bridge"
import {graphql} from 'gatsby'
import Header from '../components/Header'


const Home = ({data}) => {

  const heading = data.allContentfulHero.nodes[0].heading
  const subHeading = data.allContentfulHero.nodes[0].subHeading
  const imageData = data.allContentfulHero.nodes[0].image.gatsbyImageData
  const footerData = data.allContentfulHero.nodes[0].footer.gatsbyImageData


  const bgImage = convertToBgImage(imageData)
  const footerImage = convertToBgImage(footerData)

  return (
    <>
    <SEO title= " Afterglow Property Services | Make Your Garden Glow"/>
    <Layout>
      <main className= "homepage">
        {/* <Hero image= {bgImage} heading= {heading} subHeading= {subHeading}/> */}
        <Header/>
        {/* <Gallery/> */}
        {/* <About/> */}
        <Services/>
        <Contact background= {footerImage}/>
      </main>
    </Layout>
      
    </>
  )
}

export const query = graphql`
{
  allContentfulHero {
    nodes {
      heading
      subHeading
      image {
          gatsbyImageData(placeholder: BLURRED)
      }
      footer {
          gatsbyImageData(placeholder: BLURRED)
      }
    }
  }
}
`


export default Home



